<p-menubar #primMenuBar [model]="menuItems">
    <div class="dx-menubar-base">
        <ng-container  *ngFor="let ctrl of adjacentItems" >
                <ng-container *ngIf="ctrl.controlType === 'avatar'" >
                    <dx-avatar></dx-avatar>
                </ng-container>
                <ng-container *ngIf="ctrl.controlType === 'search'" >
                    <input type="text" pInputText placeholder="Search">
                </ng-container>
                <ng-container *ngIf="ctrl.controlType === 'bell'" >
                    <button pButton pRipple type="button" class="p-button-rounded  p-button-secondary p-button-text" (click)="menu.toggle($event)">
                        <i *ngIf="ctrl.badgeNotifEnable" class="fa fa-bell badge-danger-size" aria-hidden="true" pBadge style="font-size: 1.3rem;" 
                        severity="danger"></i>
                        <i *ngIf="!ctrl.badgeNotifEnable" class="fa fa-bell" aria-hidden="true"  style="font-size: 1.3rem;" ></i>
                    </button>
                    <p-menu #menu [popup]="true" [model]="ctrl.listItems || []"></p-menu>
                </ng-container>
                <ng-container *ngIf="ctrl.controlType === 'button' && ctrl.config.visible" >
                    <dx-button [text]="ctrl.config.text" [icon]="ctrl.config.icon" (onClick)="ctrl.config.onClick($event)"></dx-button>
                </ng-container>
        </ng-container>
        <!-- <button type="button" pButton label="Logout" icon="pi pi-power-off" style="margin-left:.25em"></button> -->
    </div>
    <!-- <ng-template *ngIf="footer" pTemplate="controls">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-template> -->
</p-menubar>