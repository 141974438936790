import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class DxControlUtilsService {
    public dsepVersion: string = "v0.2";
    constructor(private http: HttpClient,
                private domSanitizer: DomSanitizer,
             ){ 

             }

    registeredComponents: any = {};
    getComponent(id: string){
        return  this.registeredComponents[id];
    }
    addComponent(control: any){
        let existingControl =  this.registeredComponents[control["id"]];
        if(!existingControl){
            this.registeredComponents[control["id"]] = control;
        }else{
            if(existingControl !== control){
                this.registeredComponents[control["id"]] = control;
            }
        }
    }

    isBoolean(value: string | boolean | any | object | [] | {}){
        return typeof value === 'boolean';
    }

    isString(value: string | boolean | any | object | [] | {}){
        return typeof value === 'string';
    }

    isNumber(value: string | boolean | any | object | [] | {})
    {
        return typeof value === 'number';
    }

    isObject(value: string | boolean | any | object | [] | {}){
        return typeof value === 'object' && value != null;
    }

    isArray(value: string | boolean | any | object | [] | {})
    {
        return value instanceof Array;
    }

    generateGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
        });
    }
    

    applyObject(newObj: Object, sourceObj:Object, isNestedObjApplied: boolean = false)
    {
        let objResult = {};
        if(isNestedObjApplied){
            objResult = JSON.parse(JSON.stringify(sourceObj));
            Object.assign(newObj, objResult);
        }else{
            objResult = Object.assign(newObj, sourceObj);
        }
        return objResult;
    }

    // init(): Promise<any> {
    //     return new Promise<any>((resolve => {
    //         let reqJsonData = this.getJSONDataFile("svgiconlist");
    //         reqJsonData.subscribe(((data: any[]) =>{
    //              data.forEach((i => {
    //                   let pathV: String = i["path"];
    //                   const icon = { name: i["icon"], path: pathV.replace('#version', this.dsepVersion)}
    //                   this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path));
    //              }).bind(this));
    //              resolve(true);
    //         }).bind(this));
    //     }).bind(this));
    // }

    getJSONDataFile(filename:string): Observable<any> {
        return this.http.get(`../app/_shared/dsep-components/${this.dsepVersion}/_resource/json/${filename}.json`);
    }
    controlItems: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
    getItems(){
        return this.controlItems.value;
    }
}
