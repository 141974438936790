import { Component, Output, EventEmitter, Input, TemplateRef } from '@angular/core';
import { DataSourceModel } from '../_dxmodel/data-source';
import { LazyLoadEvent } from 'primeng/api';
import { BaseComponent } from '../_common/dx-base-component';
import { DxControlUtilsService } from '../_common/dx-control-utils.service';

@Component({
  selector: 'dx-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent extends BaseComponent {
    @Input() dataSource!: DataSourceModel
    @Input() columns!: any[];
    @Input() loading: boolean = false;
    @Input() showPaginator: boolean = true;
    @Output() onGridEventBind = new EventEmitter();
    @Input() rowSelectionType: 'mutiple'|'single' | '' = '';
    @Input() enableRowSelection: boolean = false;
    @Input() enableExpandableRow: boolean = false;
    @Input() dataKey: string = "";
    @Input() rowContent!: TemplateRef<any>;
    @Output() onExpandRow = new EventEmitter();
    selectedRowData!: any;
    pageFirst: number = 0;
    pagination: any = {
        currentPage: 1,
        skip: 0,
        take: 0   
    }
    constructor(private utils: DxControlUtilsService){
        super(utils)
    }
    override ngOnInit() {
        super.ngOnInit();
        this.loading = true;
        if(!this.dataSource){
            this.dataSource = new DataSourceModel();
        }else{
            this.dataSource.onAfterResponse.subscribe(data => {
                this.loading = false;
            });
            this.dataSource.loadData();
        }
      
    }
    onClickExpandRow(event:any, data:any){
        let emitData: any = {
            isExpanded: !event,
            row: data
        }
        this.onExpandRow.emit(emitData);
    }
    getSelectedRowData() {
        return this.selectedRowData;
    }
    setSelectedRowData(data: any){
        this.selectedRowData = data;
    }

    pageLoadEvent(event: LazyLoadEvent) {  
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
            if (this.dataSource) {
                let pagenew = this.dataSource.params.pagination;
                if(pagenew){
                    this.pageFirst = event.first || 0;
                    pagenew.skip = event.first;
                    if(event.rows !== pagenew.take){
                        this.pageFirst = 0;
                        pagenew.take = event.rows;
                        if(pagenew.lastPaginationToken){
                            pagenew.lastPaginationToken = '';
                        }
                    }
                }
                this.dataSource.loadData();
            }
        }, 1000);
    }

}
