import { Component, Input, SimpleChange, ViewChild } from '@angular/core';
import { IdxAdjacentItems } from '../../_interfaces/icontrols-common/imenubar.interface';
import { IdxMenuItem } from '../../_interfaces/iprimeng/irefobject.interface';
import { MenuItem } from 'primeng/api';
import { DxControlUtilsService } from '../../_common/dx-control-utils.service';
import { Menubar } from 'primeng/menubar';

@Component({
  selector: 'dx-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenuBarComponent {
    @Input() menuItems!: IdxMenuItem[];
    @Input() adjacentItems!: IdxAdjacentItems[];
    @Input() listItems!: MenuItem[];
    @Input() badgeNotifEnable: boolean = false;

    @ViewChild("primMenuBar") primMenu!: Menubar;
    constructor(){

    }
    ngOnInit() {

    }
    ngOnChages(change: SimpleChange){
      let temp = this.primMenu;
    }
    isReloadItems: boolean = false;
    reloadItems(menuItems: any[], adjacentItems: any[]){
        this.menuItems = menuItems;
        this.adjacentItems = adjacentItems;
    }
}
