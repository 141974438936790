import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
}) 
export class DxToastService {

  constructor(private toasSvc: ToastrService) {
  }
  dataNotification: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  openSuccessfulMessage(message: string, duration: number = 5000, isDateRequired:boolean = false){
    let date;
    if (isDateRequired) {
      date = '';
    } else{
      date = new Date()
    }
    // this._snackBar.open(`${new Date()}\n\n${message}`, '', {duration: duration, verticalPosition: 'top', panelClass: 'snack-bar-successful'})
    this.toasSvc.success(`${date}\n\n${message}`, '')
  }

 openWarningfulMessage(message: string, duration: number = 5000, isDateRequired:boolean = false){
    this.toasSvc.warning(`${message}`, '', {timeOut: duration, positionClass: 'toast-top-center'})
  }

  openErrorMessage(message: string, duration: number = 5000,  isDateRequired:boolean = false, isAlignCenter:boolean = true){
    let msgFinal: any;
   if (!isDateRequired) {
      msgFinal = `${new Date()}\n\n${message} `;
   }else{
      msgFinal = message;
   }

   let toastrConfig = {timeOut: duration, positionClass: 'toast-top-center', messageClass: ''};
   if(isAlignCenter){
    toastrConfig.messageClass = 'toast-msg-align-center toast-msg-pre-line';
   }

    // this._snackBar.open(`${new Date()}\n\n${message}`, '', {duration: duration, verticalPosition: 'top', panelClass: 'snack-bar-error'})
    setTimeout(() => {
      this.toasSvc.error(msgFinal, '', toastrConfig);
    });
   
  }

  openErrorToastr(message: string, duration: number = 5000){
    this.toasSvc.error(`${new Date()}\n\n${message}`, '', {timeOut: duration, positionClass: 'toast-top-center'})
  }

  openSuccessToastr(message: string, duration: number = 5000){
    this.toasSvc.success(`${new Date()}\n\n${message}`, '', {timeOut: duration, positionClass: 'toast-top-center'})
  }

  openWarningToastr(message: string, duration: number = 5000){
    this.toasSvc.warning(`${new Date()}\n\n${message}`, '', {timeOut: duration, positionClass: 'toast-top-center'})
  }

}

