import { NgModule } from '@angular/core';
import {MenubarModule} from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {TableModule} from 'primeng/table';
import {PanelModule} from 'primeng/panel';
import {ToolbarModule} from 'primeng/toolbar';
import {SplitterModule} from 'primeng/splitter';
import {TabViewModule} from 'primeng/tabview';
import {BadgeModule} from 'primeng/badge';
import { PasswordModule } from 'primeng/password';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarModule } from 'primeng/calendar';
import { ChipModule } from 'primeng/chip';
import {TooltipModule} from 'primeng/tooltip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider'
import {MenuModule} from 'primeng/menu';

@NgModule({
  imports: [
    RadioButtonModule,
    CalendarModule,
    MenubarModule,
    InputTextModule,
    ButtonModule,
    AvatarModule,
    AvatarGroupModule,
    TableModule,
    PanelModule,
    ToolbarModule,
    SplitterModule,
    TabViewModule,
    BadgeModule,
    PasswordModule,
    CheckboxModule,
    InputNumberModule,
    DropdownModule,
    ProgressSpinnerModule,
    ChipModule,
    TooltipModule,
    BreadcrumbModule,
    MultiSelectModule,
    DialogModule,
    DividerModule,
    MenuModule
  ],
  declarations: [],
  exports: [
    RadioButtonModule,
    MenubarModule,
    InputTextModule,
    ButtonModule,
    AvatarModule,
    AvatarGroupModule,
    TableModule,
    PanelModule,
    ToolbarModule,
    SplitterModule,
    TabViewModule,
    BadgeModule,
    PasswordModule,
    CheckboxModule,
    InputNumberModule,
    DropdownModule,
    ProgressSpinnerModule,
    CalendarModule,
    ChipModule,
    TooltipModule,
    BreadcrumbModule,
    MultiSelectModule,
    DialogModule,    
    DividerModule,
    MenuModule
  ],
  providers: []
})
export class PrimeNgAngularModule { }
